import { Link } from "react-router-dom";
import { DriveReferenceEntry } from "../../../pages/drive/apiReference";

export const driveMethods: Record<string, DriveReferenceEntry> = {
    "api.mobile.exists": {
        description: <span>Returns true if Geotab Drive is running within a native application, and false if just as a HTML5 web application</span>,
        returnType: <span>Boolean</span>
    },
    "api.mobile.getVersion": {
        description: <span>If <Link to="/drive/apiReference/api.mobile.exists">api.mobile.exists()</Link>, gets the Geotab Drive version from the native application</span>,
        returnType: <span>String</span>
    },
    "api.mobile.speak": {
        description: <span>If <Link to="/drive/apiReference/api.mobile.exists">api.mobile.exists()</Link>, uses the text to speech functionality on the mobile device</span>,
        parameters: [{
            name: "",
            description: "",
            dataType: "String"
        }]
    },
    "api.mobile.notification.hasPermission": {
        description: <span>Asynchronously checks the notification permission for the Drive App and returns a promise.</span>,
        returnType: <div>
            <span>Promise that resolves with a message object. e.g. Response object</span>
            <code>{`{ "id":0, "text":"Message", "title":"Title", "icon":"res://ic_stat_notification", "smallIcon":"res://ic_stat_notification", "priority":1 }`}</code>
            <span>If notification is not successful it can give the following messages:</span>
            <ol>
                <li>Notification permission denied </li>
                <li>Request notification permission is not supported</li>
            </ol>
        </div>,
        codeSamples: {
            javascript: ` notification.hasPermission().then(result => console.log(result)).catch(error => console.error(error));`
        }
    },
    "api.mobile.notification.requestPermission": {
        description: <span>Checks the notification permission for the Drive App and returns a promise.</span>,
        returnType: <div>
            <span>Promise that resolves with one of the following messages:</span>
            <ol>
                <li>Notification permission not granted</li>
                <li>Notification permission granted</li>
                <li>Request notification permission is not supported</li>
            </ol>
        </div>,
        codeSamples: {
            javascript: `notification.hasPermission().then((result) => console.log('Notification permission: ', result)).catch((error) => console.error(error));`
        }
    },
    "api.mobile.notification.notify": {
        description: <span>Sends a native notification to the user with the provided title and message. If the application does not yet have notification permission, we will
            <Link to="/drive/apiReference/api.mobile.notification.requestPermission"> requestNotificationPermission()</Link> then
            <Link to="/drive/apiReference/api.mobile.notification.notify">notify()</Link> if the user granted permission; otherwise, the promise is rejected.</span>,
        parameters: [{
            name: "Message",
            description: "",
            dataType: "String"
        },
        {
            name: "Title",
            description: "",
            dataType: "String"
        },
        {
            name: "Id",
            description: "",
            isRequired: false,
            dataType: "Number"
        }],
        codeSamples: {
            javascript: `notification.notify(message, title).then(result => console.log(result)).catch( error => console.error(error);`
        },
        returnType: <div>
            <span>Promise that resolves with a message object. e.g. Response object</span>
            <code>{`{ id":0, "text":"Message", "title":"Title", "icon":"res://ic_stat_notification", "smallIcon":"res://ic_stat_notification", "priority":1 }`}</code>
            <span>If notification is not successful it can give the following messages:</span>
            <ol>
                <li>Notification permission denied</li>
                <li>Request notification permission is not supported</li>
            </ol>
        </div>
    },
    "api.mobile.notification.update": {
        description: <span>Allows you to update the content of active notifications. To update active notifications that have not yet been acknowledged, the original notification id -- created
            at the time the notification is sent -- must be provided.</span>,
        parameters: [{
            name: "Message",
            description: "",
            dataType: "String"
        },
        {
            name: "Title",
            description: "",
            dataType: "String"
        },
        {
            name: "Id",
            description: "",
            dataType: "Integer"
        }],
        codeSamples: {
            javascript: `notification.update(message, title, id).then(result => console.log(result)).catch(error => console.error(error));`
        }
    },
    "api.mobile.notification.cancel": {
        description: <span></span>,
        parameters: [{
            name: "Id",
            description: "",
            dataType: "Integer"
        }],
        codeSamples: {
            javascript: `notification.cancel(id).then(result => console.log(result)).catch(error => console.error(error))`
        }
    },
    "api.mobile.camera.takePicture": {
        description: <span>If <Link to="/drive/apiReference/api.mobile.exists">api.mobile.exists()</Link>, will open up a modal with the following options New Picture and Upload.</span>,
        returnType: <span>Promise{"<"}octet-stream{">"}</span>
    },
    "api.mobile.dutyStatusLog.getCurrentDrivingLog": {
        description: <span>"Gets the current <Link to="/myGeotab/apiReference/objects/DutyStatusLog">DutyStatusLog</Link> of the driver.</span>,
        returnType: <span>Promise that resolves with a <Link to="/myGeotab/apiReference/objects/DutyStatusLog">DutyStatusLog</Link> object.</span>
    },
    "api.mobile.dutyStatusLog.get": {
        description: <span>Gets all of the <Link to="/myGeotab/apiReference/objects/DutyStatusLog">DutyStatusLogs</Link> for the current user</span>,
        returnType: <span>Promise that resolves with an array of <Link to="/myGeotab/apiReference/objects/DutyStatusLog">DutyStatusLog</Link> objects.</span>
    },
    "api.mobile.dutyStatusLog.add": {
        description: <span>Adds a new <Link to="/myGeotab/apiReference/objects/DutyStatusLog">DutyStatusLog</Link>. Note: The log will not be immediately added with this method, it will
            be synced during the next drive sync cycle.</span>,
        parameters: [{
            name: "DutyStatusLog",
            description: "",
            dataType: "Object"
        },
        {
            name: "Datetime",
            description: "",
            dataType: "String"
        },
        {
            name: "Device",
            description: "",
            dataType: "Object"
        },
        {
            name: "Driver",
            description: "",
            dataType: "Object"
        },
        {
            name: "Status",
            description: "",
            dataType: "String"
        },
        {
            name: "Origin",
            description: "",
            dataType: "String"
        }],
        returnType: <span>Promise that resolves with the newly added <Link to="/myGeotab/apiReference/objects/DutyStatusLog">DutyStatusLog</Link> object</span>
    },
    "api.mobile.navigate": {
        description: <span>Navigates to default pages on Geotab Drive.</span>,
        parameters: [
            {
                name: "Url",
                description: "Valid page values: 'assets', 'hos', 'hos/main', 'hos/logs', 'dvir', " +
                "'messaging', 'inspection', and 'settings', 'assets/selectShipment', " +
                "'assets/SelectTrailer', 'assets/selectVehicle', 'dvir/view', 'hos/addLog', " +
                "'hos/emailLogs', 'other', 'trips'. Note: param will append the string at the " +
                "end of the URL path with a comma ',' as a delimiter",
                dataType: "String"
            }
        ]
    },
    "api.mobile.listenTo": {
        description: <span>Event listener that executes the specified callback function whenever a change on the state is detected</span>,
        parameters: [{
            name: "callback function",
            description: "",
            dataType: "" // todo: add data type
        }],
        codeSamples: {
            javascript: `listenTo((newState) => { console.log(JSON.stringify(newState)); })`
        }
    },
    "api.mobile.shipment.get": {
        description: <span>Gets the <Link to="/myGeotab/apiReference/objects/ShipmentLog">ShipmentLogs</Link> for the device.</span>,
        returnType: <span>Returns an array of
            <Link to="/myGeotab/apiReference/objects/ShipmentLog">ShipmentLogs</Link>.
            Note that the method will return all devices that have been added and removed during the current session. The list should be filtered by the activeTo property
            according to the requirement.</span>
    },
    "api.mobile.shipment.add": {
        description: <span>Adds a new <Link to="/myGeotab/apiReference/objects/ShipmentLog">ShipmentLog</Link></span>,
        parameters: [{
            name: "ShipmentLog",
            description: "",
            dataType: "Object"
        }],
        returnType: <span>Promise that resolves with the <Link to="/myGeotab/apiReference/objects/ShipmentLog">ShipmentLog</Link> object that was added.</span>,
        codeSamples: {
            javascript: `{driver: {id: '' }, activeFrom: '', dateTime: '', shipperName: '', commodity: ''}`
        }
    },
    "api.mobile.shipment.remove": {
        description: <span>Removes the specified <Link to="/myGeotab/apiReference/objects/ShipmentLog">ShipmentLog</Link> by setting the activeTo datetime string to the current date.</span>,
        parameters: [{ name: "shipmentId", description: "", dataType: "String" }],
        returnType: <span>Promise that resolves with the <Link to="/myGeotab/apiReference/objects/ShipmentLog">ShipmentLog</Link> object that was removed.</span>
    },
    "api.mobile.textMessage.add": {
        description: <span>Adds a new <Link to="/myGeotab/apiReference/objects/TextMessage">TextMessage</Link></span>
    },
    "api.mobile.textMessage.get": {
        description: <span>Returns any <Link to="/myGeotab/apiReference/objects/TextMessage">TextMessage</Link> received in the current session.</span>,
        returnType: <span>A promise that resolves with an array of <Link to="/myGeotab/apiReference/objects/TextMessage">TextMessage</Link></span>
    },
    "api.mobile.textMessage.set": {
        description: <span>Allows to update the properties for an existing <Link to="/myGeotab/apiReference/objects/TextMessage">TextMessage</Link></span>,
        parameters: [{ name: "TextMessage", description: "Note: An existing message ID is needed to modify the message.", dataType: "Object" }],
        returnType: <span>Promise that resolves with the modified <Link to="/myGeotab/apiReference/objects/TextMessage">TextMessage</Link></span>,
        codeSamples: {
            javascript: `{id: 'bd1', device: {id: 'b1'}, isDirectionToVehicle: true, messageContent: {message: 'Message', contentType: 'Normal'} }`
        }
    },
    "api.mobile.user.get": {
        description: <span>Retrieves driver information</span>,
        returnType: <span>Promise that resolves with an array of <Link to="/myGeotab/apiReference/objects/Driver">Driver</Link> objects</span>
    },
    "api.mobile.user.getHosRuleSet": {
        description: <span>Gets the HosRuleSet for the current driver</span>,
        returnType: <span>Promise that resolves with the HosRuleSet object</span>
    },
    "api.mobile.user.getAvailability": {
        description: <span>Gets driver availability</span>,
        returnType: <span>Promise that resolves with <Link to="/myGeotab/apiReference/objects/DutyStatusAvailability">DutyStatusAvailability</Link> object</span>
    },
    "api.mobile.user.getViolations": {
        description: <span>Gets driver violations</span>,
        returnType: <span>Promise that resolves with an array of <Link to="/myGeotab/apiReference/objects/DutyStatusViolation">DutyStatusViolation</Link> objects</span>
    },
    "api.mobile.vehicle.get": {
        description: <span>Retrieves current vehicle information</span>,
        returnType: <span>Promise that resolves with an object with vehicle information.</span>
    },
    "api.mobile.trailer.get": {
        description: <span>Retrieves trailer information</span>,
        returnType: <span>Promise that resolves with an array of trailer objects</span>
    },
    "api.mobile.trailerAttachment.get": {
        description: <span>Retrieves <Link to="/myGeotab/apiReference/objects/TrailerAttachment">TrailerAttachment</Link> data</span>,
        returnType: <span>Promise that resolves with an array of <Link to="/myGeotab/apiReference/objects/TrailerAttachment">TrailerAttachment</Link> objects</span>
    }
};

export const driveObjects: Record<string, DriveReferenceEntry> = {
    "api.mobile.geolocation": {
        description: <span>A navigator object that is similar to HTML5 navigator.geolocation</span>,
        codeSamples: {
            javascript: `api.mobile.geolocation.getCurrentPosition(function (position) , function (error) , {enableHighAccuracy: true });`
        }
    },
    state: {
        properties: [
            {
                name: "device",
                description: "	Get the current vehicle that is being connected to the mobile device",
                dataType: "String"
            },
            {
                name: "driving",
                description: "Mobile device is detected as driving with the current vehicle",
                dataType: "Boolean"
            },
            {
                name: "charging",
                description: "Mobile device is being powered",
                dataType: "Boolean"
            },
            {
                name: "background",
                description: "	Geotab Drive application is running in the background",
                dataType: "Boolean"
            },
            {
                name: "online",
                description: "Mobile device has internet access",
                dataType: "Boolean"
            },
            {
                name: "deviceCommunicating",
                description: "Telematics device is communicating to the server",
                dataType: "Boolean"
            },
            {
                name: "gpsConnected",
                description: "Mobile device has GPS enabled",
                dataType: "Boolean"
            }
        ]
    }
};
